import {createApp} from 'vue'

import Header from "./components/Header.vue";

const header = createApp(Header).mount('#header-container');

setTimeout(function () {
    $('.alert-email').fadeOut();

}, 5000)
$('.alert-email').click(
    function () {
        $(this).fadeOut();
    }
);

$('#newsform').submit(function (evt) {
    const hasSubmitted = localStorage.getItem('hasSubmitted');
    if (hasSubmitted == 'true') {
        evt.preventDefault();
    } else {
        localStorage.setItem('hasSubmitted', true);
    }
});
