<template>
  <div class="main-header-surcontainer" :class="{'is-active': isActive }">
    <header @mouseleave="sublinks = null;">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 header-surcontainer">
            <div class="header-container">
              <a class="logo" href="/">
                <img class="logo" src="../../images/logo-galia.png" alt="">
              </a>
              <div class="navigation-container hide-mobile">
                <template v-for="link in links">
                  <a @mouseover="sublinks = link.acf.sublinks" :href="link.acf.link" v-if="link.acf.sublinks"
                     v-html="link.title.rendered">
                  </a>
                  <a @mouseover="sublinks = null" :href="link.acf.link" v-else v-html="link.title.rendered">
                  </a>
                </template>
              </div>
              <a class="button hide-mobile" href="/contact">Nous contacter</a>
              <div class="hamburger hide-desktop" :class="{'is-active': isActive }" @click="isActive = !isActive">
                <span class="line"></span>
                <span class="line"></span>
                <span class="line"></span>
              </div>
            </div>
            <transition name="height" mode="out-in">
              <div class="sublinks" v-if="sublinks" :key="sublinks">
                <a v-for="link in sublinks" :href="link.link">{{ link.name }}</a>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </header>
    <div class="hide-desktop header-links-mobile">
      <div class="container">
        <div class="row">
          <transition name="height" mode="out-in">
            <div class="col-xs-12 header-nav-mobile" v-if="isActive">
              <template v-for="link in links">
                <div v-if="link.acf.sublinks">
                  <span @click="toggleSelectedLink(link.title.rendered)">{{ link.title.rendered }}</span>
                  <div class="mobile-sublinks" v-if="selectedLink == link.title.rendered">
                    <a v-for="link in link.acf.sublinks" :href="link.link">{{ link.name }}</a>
                  </div>
                </div>
                <a :href="link.acf.link" v-else>{{ link.title.rendered }}</a>
              </template>
              <a class="button" href="/contact">Nous contacter</a>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Header",
  components: {},
  data() {
    return {
      sublinks: null,
      isActive: false,
      selectedLink: null,
      links: [],
    };
  },
  computed: {},
  async created() {
    await this.initialiseData();
  },
  watch: {
    sublinks: function () {
    },
  },
  methods: {
    async initialiseData() {
      this.links = await $.ajax({
        url: window.location.origin + '/wp-json/wp/v2/menus?acf_format=standard&orderby=date&per_page=100&order=asc',
        method: "GET",
        cache: false,
        contentType: false,
        processData: false,
        headers: {'X-Requested-With': 'XMLHttpRequest'},
        success: function (data) {
          return data
        },
        error: function (e) {
        }
      });
      // datalinks.forEach((el) => {
      //   if (el.acf.sublinks) {
      //     let datasublinks = [];
      //     el.acf.sublinks.forEach(function (sublink) {
      //       datasublinks.push(
      //           {
      //             'name': sublink.post_title,
      //             'link': sublink.acf.link,
      //           }
      //       )
      //     })
      //     this.links.push(
      //         {
      //           'name': el.title.rendered,
      //           'link': el.acf.link,
      //           'links': datasublinks,
      //         }
      //     )
      //   } else {
      //     if (el.acf.is_main_link) {
      //       this.links.push(
      //           {
      //             'name': el.title.rendered,
      //             'link': el.acf.link,
      //           }
      //       )
      //     }
      //   }
      // })
    },
    toggleSelectedLink: function (selectedLink) {
      if (this.selectedLink == selectedLink) {
        this.selectedLink = null;
      } else {
        this.selectedLink = selectedLink;
      }
    }
  }
};
</script>
